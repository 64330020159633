import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

if(typeof window !== 'undefined') {
    window.googlefc = window.googlefc || {};
    window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
}

const InfoPage = () => (
    <Layout>        
        <SEO title="Tietoa palvelusta" description="Tietosuoja ja tietoa kouluruoka.fi palvelusta" />
        <h1 className='pageTitle'>Tietosuoja ja tietoa palvelusta</h1>
        <p>
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>Muutokset ruokalistoilla ovat mahdollisia, tarkistathan allergiat aina keittiöhenkilökunnalta.</span>
        </p>
        <p>
            <strong>Henkilötietojen käsittely</strong><br />
            Tämä sivusto ei kerää henkilötietoja.
        </p>
        <p>
            <strong>Evästeet</strong><br />
            Sivustollamme käytetään evästeitä (cookies). Evästeet ovat tiedostoja, joita voidaan käyttää antamiesi tietojen säilyttämiseksi. Evästeet sisältävät nimettömän, numeerisen tunnisteen, jonka avulla voimme yksilöidä ja laskea sivustollamme vierailevat selaimet.
        </p>
        <p>
            <strong>Evästeiden käyttö</strong><br />
            Osa evästeistä on välttämättömiä, jotta verkkosivut toimivat oikein, osa taas parantaa sivujen toiminnallisuutta ja käytettävyyttä. Evästeet mahdollistavat esimerkiksi sen, että käyttäjän ei tarvitse sivustolla vieraillessa aina valita oma ravintolaa. Osa evästeistä kerää tietoa sivuston käytöstä (statistiikkaa), jotta sivustoa voidaan kehittää entistä käyttäjäystävällisemmäksi ja hyödyllisemmäksi.
            <br /><br />
            Hyödynnämme evästeitä ja muita vastaavia tekniikoita (Google Analytics) myös kävijämäärien tilastollisessa seurannassa. Sivuston käytön analyysitietoa ei käsitellä tai talleteta siten, että yksittäinen käyttäjä olisi tunnistettavissa.
        </p>
        <p>
            <strong>Evästeiden hallinta</strong><br />
            Mikäli käyttäjä ei halua, että me tai yhteistyökumppanimme kohdistamme mainontaa tai sisältöä internet-käyttäytymisen perusteella, käyttäjä voi estää tämän kieltämällä haluamansa evästeet selaimensa asetuksista. Evästettä vastaavan mobiilitunnisteen voi nollata laitteen asetuksista.
            <br /><br />
            Tyhjentämällä evästeet tai mobiilitunnisteen säännöllisin väliajoin käyttäjä vaihtaa tunnistetta, jonka pohjalta käyttäjästä muodostuu profiili. Evästeiden tyhjentäminen ei kuitenkaan lopeta kokonaan tiedon keruuta, vaan nollaa aikaisempiin käyttäytymistietoihin pohjautuvan profiilin.
        </p>
        <p>
            <strong>Mainokset</strong><br />
            Kolmannen osapuolen palveluntarjoajat, myös Google, käyttävät evästeitä. Niiden avulla ne näyttävät mainoksia sellaisten tietojen perusteella, jotka on saatu käyttäjän aiemmista vierailuista sivustollasi tai muilla sivustoilla.
            <br /><br />
            Googlen mainosevästeiden avulla Google ja sen kumppanit voivat näyttää mainoksia käyttäjille niiden tietojen perusteella, jotka on saatu käyttäjien käynneistä sivustoillasi ja/tai muilla sivustoilla.
            <br /><br />
            Käyttäjät voivat poistaa räätälöidyn mainonnan käytöstä <a href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">mainosasetuksissa</a> tai <a href="javascript:googlefc.callbackQueue.push(googlefc.showRevocationMessage)">tietosuoja- ja keksiasetuksista</a>.
        </p>
        <p>
            <strong>Muutokset tietosuojakäytäntöön</strong><br />
            Voimme muuttaa ja päivittää tätä tietosuojakäytäntöä, jotta se heijastaisi mahdollisia muutoksia siihen, miten käsittelemme sinua koskevia tietoja. Tarvittaessa teemme päivityksen tietosuojakäytäntöön, joka on nähtävissä verkkosivustollamme.
        </p>
        <p>
            Palautteet ja tietosuojaan liittyvät kyselyt voi lähettää <Link to="/palaute/">palautelomakkeen</Link> kautta tai osoitteeseen <a href="mailto:tietosuoja@kouluruoka.fi">tietosuoja@kouluruoka.fi</a>.
        </p>
        <p>
            Palvelun logot: <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noreferrer">Freepik</a> osoitteesta <a href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noreferrer">www.flaticon.com</a><br />
            Google Play ja Google Play logo ovat Google LLC tavaramerkkejä.
        </p>
        <p>
            Sovelluksen julkaisija Play Storessa: Bertil Dator.
        </p>
    <p>
    <strong>Sanapeli</strong>
        Sanapeli käyttää Kotimaisten kielten keskuksen Nykysuomen sanalistaa. Päivitetty 30.4.2024 [viitattu 15.11.2024]. Saatavissa <a href="https://kaino.kotus.fi/lataa/nykysuomensanalista2024.csv" target="_blank" rel="noreferrer">https://kaino.kotus.fi/lataa/nykysuomensanalista2024.csv</a>. Listaa muokattu kevyesti poistamalla rumia sanoja sekä sanoja, joissa erikoisempia kirjaimia.
    </p>
        <hr />
        <h1 className='pageTitle'>kouluruoka.fi-mestari: tarkemmat tiedot ja henkilötietojen käsittely<a href="#" name="mestari"></a></h1>
        <p>
            Ollaksesi kouluruoka.fi-mestari, sinun tulee olla yli 13 vuotias. Tarvitset lisäksi luvan vanhemmiltasi jos olet alle 18 vuotias.
            <br /><br />
            Voit milloin tahansa lopettaa mestarin tehtävät, ilmoita vaan meille sähköpostilla niin tiedämme etsiä tilallesi uuden mestarin.
            Myös me voimme päättää lopettaa mestariohjelman milloin tahansa tai päättää sulkea yksittäisen mestarin käyttäjätunnukset esim. väärinkäytösten vuoksi.
            <br /><br />
            <i>Rekisterin pitäjä:</i> Btor, Sahasaarentie 1, 06650 Hamari, Y-tunnus: 3259337-3<br />
            <i>Yhteystieto rekisteriä koskevissa asioissa:</i> <a href="mailto:tietosuoja@kouluruoka.fi">tietosuoja@kouluruoka.fi</a>, Jussi Palo<br />
            <i>Rekisterin nimi:</i> Kouluruoka.fi-mestariohjelman käyttäjärekisteri<br />
            <i>Henkilötietojen käsittelyn tarkoitus ja oikeusperuste:</i> Tuotteiden ja palveluiden tuottaminen<br />
            <i>Rekisterin tietosisältö:</i> Henkilön etu- ja sukunimi, nimimerkki, sähköpostiosoite. Alaikäisen henkilön huoltajan/huoltajien etu- ja sukunimi, sähköpostiosoite, puhelinnumero.<br />
            <i>Tietojen säännönmukainen luovutus:</i> Btor ei säännönmukaisesti luovuta tämän rekisteriselosteen mukaisia henkilötietoja ulkopuolisille paitsi Suomen viranomaisten niin lain mukaan edellyttäessä. Henkilötietoja ei myydä, vuokrata tai luovuteta kolmannelle osapuolelle.<br />
            <i>Tietojen siirto EU:n tai ETA:n ulkopuolelle:</i> Tietoja ei siirretä tai luovuteta EU:n tai Euroopan talousalueen ulkopuolelle.<br />
            <i>Rekisterin suojauksen periaatteet:</i> Rekisteriin on pääsy määrätyillä Btor:n henkilöillä, joiden työtehtävät edellyttävät rekisterin käyttöä. Rekisterin käyttö on suojattu henkilökohtaisilla käyttäjätunnuksilla, joiden käyttö edellyttää vahvaa tunnistautumista.<br />
            <i>Henkilötietojen säilytysaika ja poistaminen:</i> Btor:n asiakasrekisterin henkilötietoja säilytetään niin kauan, kun henkilötietojen käsittely on tarpeen Btor:n tai asiakkaan välisen toimeksiantosopimuksen (kouluruoka.fi-mestariohjelma) tai muun oikeutetun edun toteuttamiseksi. Btor arvioi asiakasrekisterin henkilötietojen oikeusperustetta tarpeen mukaan. Tarvittaessa Btor suorittaa toimenpiteitä, joilla epätarkat, virheelliset, vanhentuneet tai tarpeettomat henkilötiedot poistetaan. Henkilötiedot poistetaan kun kouluruoka.fi-mestariohjelman jäsenyys lakkaa.
        </p>
    </Layout>
)

export default InfoPage


